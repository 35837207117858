<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="10">
							<pui-text-field
								:id="`description-${modelName}`"
								v-model="model.description"
								:label="$t('form.stopstatustransition.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="5">
							<pui-select
								:id="`statusfrom-${modelName}`"
								:attach="`statusfrom-${modelName}`"
								:label="$t('form.stopstatustransition.statusfrom')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="stopstatuspasf"
								:itemsToSelect="[{ stopstatuscode: this.model.statusfrom }]"
								:modelFormMapping="{ stopstatuscode: 'statusfrom' }"
								itemValue="stopstatuscode"
								itemText="stopstatusname"
								:order="{ stopstatusname: 'asc' }"
								:fixedFilter="filterByPortAuthority"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="5">
							<pui-select
								:id="`statusto-${modelName}`"
								:attach="`statusto-${modelName}`"
								:label="$t('form.stopstatustransition.statusto')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="stopstatuspasf"
								:itemsToSelect="[{ stopstatuscode: this.model.statusto }]"
								:modelFormMapping="{ stopstatuscode: 'statusto' }"
								itemValue="stopstatuscode"
								itemText="stopstatusname"
								:order="{ stopstatusname: 'asc' }"
								:fixedFilter="filterByPortAuthority"
								reactive
							></pui-select>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'stopstatustransition-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'stopstatustransition',
			parentModelName: 'portauthority'
		};
	},
	methods: {
		afterGetData() {
			this.setParentPk();
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.portauthorityid = parentModel.pk.id;
			}
		}
	},
	computed: {
		filterByPortAuthority() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: this.model.portauthorityid }]
			};
		}
	},
	created() {}
};
</script>
